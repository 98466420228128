import axios from 'axios';
import Layout from 'components/common/NewLayout';
import Section1 from 'components/download/Section1';
import Section2 from 'components/download/Section2';
import Section3 from 'components/download/Section3';
import Section4 from 'components/download/Section4';
import Title from 'components/download/Title';
import React, { useState } from 'react';

const Download = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleDownload = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const decodedFilename = decodeURIComponent(name.replace(/\+/g, ' '));
      a.href = objectUrl;
      a.download = decodedFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error('파일 다운로드 중 에러가 발생했습니다.', error);
    }
  };

  const onSubmit = data => {
    if (data.term !== 'Y') {
      window.alert('개인정보 이용에 동의해주세요');
      return;
    }
    setIsSubmiting(true);

    const createQuery = `mutation { create_item (board_id: 7415322293, item_name: "[몰파이] 소개서 다운로드 - 공통에 새 응답이 접수되었습니다.") { id }}`;

    axios
      .post(
        'https://api.monday.com/v2',
        JSON.stringify({
          query: createQuery,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
          },
        },
      )
      .then(res => {
        const updateQuery = `mutation { create_update (item_id: ${res.data.data.create_item.id}, body: "도입 유형: ${data.type}<br/> 기업/브랜드명: ${data.compNm}<br/> 담당자 연락처: ${data.mobile}<br/> 이메일 주소: ${data.id}@${data.id2}<br/> 맞춤 상담:${data.option}<br/> 마케팅 동의여부:${data.marketing}<br/>") { id }}`;
        axios
          .post(
            'https://api.monday.com/v2',
            JSON.stringify({
              query: updateQuery,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization:
                  'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
              },
            },
          )
          .then(async () => {
            await handleDownload(
              '/pdf/b2b.pdf',
              '몰파이 B2B 서비스 제안서(지니웍스)',
            );
            alert('몰파이 기업 소개서가 다운로드 되었습니다.');
            window.location.reload();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      });
  };

  return (
    <Layout theme="white">
      <Title />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 onSubmit={onSubmit} isSubmiting={isSubmiting} />
    </Layout>
  );
};

export default Download;
